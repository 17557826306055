import { CustomBox } from "@/common/StyledComponents";
import { Button, CircularProgress, Grid, IconButton, Modal, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { closeLinesPopup, distributeRemaining, resetAll, resetAllocations, selectAllocations, selectToAllocateBills, selectToAllocateLines, stopAllocation } from "./actualSlice";
import { useDispatch } from "react-redux";
import { AccountingBill, AccountingBillHeaderLine } from "./AccountingBill";
import { ActualHeaderLine, ActualLine } from "./ActualLine";
import { formattedCurrency } from "@/utils";
import { AutoFixHigh } from "@mui/icons-material";
import { request } from "@/Api";
import { useState } from "react";

export default function AllocationPopup({getProject, openSnackBar}){
    const dispatch = useDispatch()
    const theme = useTheme()
    const allocations = useSelector(selectAllocations)

    const lines = useSelector(selectToAllocateLines)
    const bills = useSelector(selectToAllocateBills)

    const isMultiLine = lines.length > 1

    const handleClose = () => {
        dispatch(stopAllocation())
        dispatch(closeLinesPopup())
    }

    const onAllocate = () => {
        getProject()
        handleClose()
        openSnackBar()
        dispatch(resetAll())
    }

    if (allocations.length == 0) {
        return
    }

    return (
    <Modal open={allocations != null} onClose={handleClose}>
        <CustomBox sx={{ 
            paddingLeft: 4,
            paddingRight: 4,
            background: theme.palette.background.default, 
            overflow: "scroll",
            width: "80%",
        }}>
            {isMultiLine ? 
            <MultilineAllocation 
                lines={lines}
                bill={bills[0]}
                allocations={allocations}
                onAllocate={onAllocate}
            /> : 
            <SingleLineAllocation 
                line={lines[0]}
                bills={bills}
                allocations={allocations}
                onAllocate={onAllocate}
            />}
        </CustomBox>
    </Modal>
    )
}

const SingleLineAllocation = ({line, bills, allocations, onAllocate}) => {
    const [ waiting, setWaiting ] = useState(false)
    const confirmAllocate = () => {
        setWaiting(true)
        request.post("allocation", allocations)
        .then(()=>{
            onAllocate()
        })
        .finally(()=>{
            setWaiting(false)
        })
    }

    return (
        <>
            <Grid container spacing={2} alignItems={"center"} paddingBottom={3}>
                <Grid item>
                    <p>{`Allocate Actual Line`}</p>
                </Grid>
                <Grid item>
                    <p style={{color: "gray", fontSize: 12}}>{line.name}</p>
                </Grid>
            </Grid>
            <AccountingBillHeaderLine isAllocating={true}/>
            {bills.map((bill, index)=>
                <AccountingBill 
                    key={index}
                    billId={bill.id}
                    isLeaf={true}
                    allocations={allocations}
                />
            )}
            <div style={{textAlign: "right"}}>
                {waiting ? <CircularProgress /> : <Button onClick={confirmAllocate} variant="contained">Fully allocate all selected bills</Button>}
            </div>
        </>
    )
}

const MultilineAllocation = ({lines, bill, allocations, onAllocate}) => {
    const [ waiting, setWaiting ] = useState(false)
    const dispatch = useDispatch()
    const allocatedTotal = allocations.reduce((total, allocation)=>total + allocation.actual, 0)

    const confirmAllocate = () => {
        setWaiting(true)
        request.post("allocation", allocations)
        .then(()=>{
            onAllocate()
        })
        .finally(()=>{
            setWaiting(false)
        })
    }

    return (
        <>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Grid container spacing={2} alignItems={"center"} paddingBottom={3}>
                        <Grid item>
                            <p>{`Allocate Bill #${bill.accounting_bill_number}`}</p>
                        </Grid>
                        <Grid item>
                            <p style={{color: "gray", fontSize: 12}}>{`${bill.vendor_name} | ${formattedCurrency(bill.actual, bill.currency, false, 2)}`}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton size="large" onClick={()=>{dispatch(distributeRemaining())}}>
                        <AutoFixHigh />
                    </IconButton>
                </Grid>
            </Grid>

            <ActualHeaderLine isAllocating={true} />
            {lines.map((line, index)=>
                <ActualLine
                    key={index}
                    lineId={line.id}
                    isLeaf={true}
                    allocations={allocations}
                    billCurrency={bill.currency}
                />
            )}

            <Grid container spacing={3} justifyContent={"right"} marginTop={0} marginBottom={4}>
                <Grid item>Total:</Grid>
                <Grid item>{formattedCurrency(allocatedTotal, bill.currency)}</Grid>
            </Grid>

            <div style={{textAlign: "right"}}>
                {waiting ? <CircularProgress /> : <Button 
                    onClick={confirmAllocate}
                    disabled={bill.actual != allocatedTotal}
                    variant="contained"
                >
                    {
                        allocatedTotal < bill.actual ? `${formattedCurrency(bill.actual - allocatedTotal, bill.currency, false, 2)} left to allocate` :
                        allocatedTotal > bill.actual ? `${formattedCurrency(allocatedTotal - bill.actual, bill.currency, false, 2)} over allocated` :
                        "Confirm Allocation"
                    }
                </Button>}
            </div>
        </>
    )
}